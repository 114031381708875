<template>

  <div class="container">

    <div class="page-header">
      <h2>Stock on hand Report</h2>
      <tabs :tab="routeName"></tabs>
    </div>

    <alert
      v-if="weekStockUpdated"
      type="success"
      message="Your stock for the week has been saved successfully."
      @close="weekStockUpdated = false"></alert>
    <alert
      v-if="error"
      type="danger"
      message="Something went wrong; couldn't save your changes."
      @close="error = false"></alert>

    <div class="row row-deck">

      <div v-if="can(uiPermissions.REPORTS_INGREDIENTS_WEEKLY_STATUS_VIEW)" class="col-6">
        <weekly-stock
          :routeName="routeName"
          @filterDate="date => updateDate(date)"
          @success="updateWeekSuccess"
          @error="error = true"></weekly-stock>
      </div>

      <div :class="can(uiPermissions.REPORTS_INGREDIENTS_WEEKLY_STATUS_VIEW) ? 'col-6' : 'col-12'">
        <stock-articles :routeName="routeName" :startDate="calendar.startDate"></stock-articles>
      </div>

    </div>

    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              Stocks by {{`${moment(calendar.startDate).format('dddd, D MMM YYYY')}`}}
            </div>
            <div class="card-options">
              <input
                v-model="query"
                type="search"
                placeholder="Search article"
                class="form-control w-auto"/>
            </div>
          </div>
          <div v-if="routeName === 'articles'" class="card-header">
            <div class="card-options">
              <div class="form-group mb-0">
                <div class="btn-group btn-group-toggle ml-2">
                  <button
                    v-if="can(uiPermissions.REPORTS_INGREDIENTS_EXPORT)"
                    class="btn btn-primary"
                    @click="exportStock">
                    Export
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="table-responsive" style="max-height: 40em;">
            <table class="table table-striped table-outline table-vcenter card-table dataTables_wrapper sticky-table">
              <thead>
                <tr class="thead-light">
                  <th>Ingredient</th>
                  <th>Category</th>
                  <th class="text-center">Counted quantity</th>
                  <th class="text-center">Counted value</th>
                </tr>
              </thead>
              <tbody v-for="group in filteredResults" :key="group.id">
                <tr class="cursor-pointer" @click.prevent="group.show = !group.show">
                  <td>
                    <div class="d-flex justify-content-between align-items-center">
                      {{group.ingredient.name}}
                      <i class="fe" :class="group.show ? 'fe-minus text-danger' : 'fe-plus text-success'"></i>
                    </div>
                  </td>
                  <td>{{group.ingredient.category.name}}</td>
                  <td class="text-center">{{numeral(group.ingredient.counted_quantity).format('0,0[.]00')}}</td>
                  <td class="text-center">{{numeral(group.ingredient.counted_value).format('0,0.00')}}</td>
                </tr>
                <tr v-if="group.show">
                  <td colspan="4">
                    <table class="table table-bordered table-vcenter">
                      <thead>
                        <tr>
                          <th>Article</th>
                          <th
                            v-b-tooltip.hover
                            title="Ordering unit of measure"
                            class="cursor-pointer">
                            OUM <i class="fe fe-help-circle text-muted"></i>
                          </th>
                          <th class="text-center">Packing size</th>
                          <th class="text-center">Cost price</th>
                          <th class="text-center">Quantity</th>
                          <th class="text-center">Total value</th>
                          <th class="text-center">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="article in group.article_stocks" :key="article.id">
                          <td>{{article.article.name}}</td>
                          <td>{{article.ordering_uom.name}}</td>
                          <td class="text-center">{{(article.preferred_replenishment || {}).pack_size_per_oum}}</td>
                          <td class="text-center">{{(article.preferred_replenishment || {}).cost_price}}</td>
                          <td class="text-center">{{article.qty}}</td>
                          <td class="text-center">{{article.value}}</td>
                          <td class="text-center">
                            <i class="fe fe-circle rounded-circle mr-1" :class="`bg-${Object.values(StockStatus).find(item => item.status === article.status).color} text-${Object.values(StockStatus).find(item => item.status === article.status).color}`"></i>
                            {{Object.entries(StockStatus).find(item => item[1].status === article.status)[0]}}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
              <tr v-if="items.length === 0">
                <td colspan="4">There are no results matching your criteria.</td>
              </tr>
            </table>
          </div>
          <div class="card-footer">
            <calendar-day-picker
              v-model="calendar"
              class="form-group col mb-0"
              :disable-days="true"
              @input="() => fetchGroupedStocks()"></calendar-day-picker>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import CalendarDayPicker from '@/components/CalendarDayPicker.vue';
import download from '@/mixins/download';
import sort from '@/mixins/sort';
import {inventory, inventoryIngredientsGrouped} from '@/services';
import Tabs from '@/views/inventory/reports/stocks/Tabs.vue';
import StockArticles from '@/views/inventory/reports/stocks/StockArticles.vue';
import WeeklyStock from '@/views/inventory/reports/stocks/WeeklyStock.vue';
import submitting from '@hellochef/shared-js/mixins/submitting';
import moment from 'moment-timezone';
import StockStatus from '@/assets/enums/StockStatus';

export default {

  components: {

    CalendarDayPicker,
    StockArticles,
    Tabs,
    WeeklyStock,
  },
  mixins: [

    download,
    sort,
    submitting,
  ],
  props: {

    routeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {

      StockStatus,
      calendar: {

        confirm: true,
        startDate: moment().startOf('week'),
      },
      error: false,
      items: [],
      query: '',
      range: {

        column: null,
        max: null,
        min: null,
      },
      weekStockUpdated: false,
      weeks: null,
    };
  },
  computed: {

    filteredResults() {
      let results = this.items;

      if (this.query.length) {
        results = this.items.filter(item => {
          return ((item.ingredient?.name || '').toLowerCase().includes(this.query.toLowerCase()) ||
                        (item.ingredient?.category?.name || '').toLowerCase().includes(this.query.toLowerCase()));
        });
      }

      return results;
    },
  },
  watch: {
    routeName() {
      this.fetchGroupedStocks();
      // Reset the calendar selection when moving to different route.
      // This will trigger refresh of stock articles too.
      this.calendar = {

        confirm: true,
        startDate: moment().startOf('week'),
      };
    },
  },
  mounted() {
    this.fetchGroupedStocks();
  },

  methods: {
    expandCollapseAll(show) {
      this.items.forEach(item => {
        item.show = show;
      });
    },
    async exportStock() {
      const payload = {

        end: moment(this.calendar.startDate).format('YYYY-MM-DD'),
        start: moment(this.calendar.startDate).format('YYYY-MM-DD'),
        allowed_in_recipes: this.routeName === 'articles' ? 1 : 0,

      };

      this.download(await inventory.exportIngredientsByParams(payload));
    },
    async fetchGroupedStocks() {
      this.items = [];

      const payload = {

        end: moment(this.calendar.startDate).format('YYYY-MM-DD'),
        start: moment(this.calendar.startDate).format('YYYY-MM-DD'),
        allowed_in_recipes: this.routeName === 'articles' ? 1 : 0,

      };

      const {groupedStock} = await inventoryIngredientsGrouped.getByParameters(payload);

      this.items = groupedStock.map(item => Object.assign(item, {show: false}));
    },
    async updateDate(date) {
      this.calendar.startDate = moment(date);

      this.fetchGroupedStocks();
    },
    updateWeekSuccess() {
      this.weekStockUpdated = true;
      this.fetchGroupedStocks();
    },
  },
};

</script>
