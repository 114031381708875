<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        Weekly stock status
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-outline table-vcenter card-table">
        <thead>
          <tr>
            <th class="w-1"></th>
            <th>Week</th>
            <th>Stock value</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.week">
            <td class="text-center">
              <div
                v-if="StockStatus[item.status].status === 'final' || StockStatus[item.status].status === 'pending'"
                class="item-action dropdown">
                <a
                  tabindex="0"
                  data-toggle="dropdown"
                  class="icon"><i class="fe fe-more-vertical"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <button
                    v-if="StockStatus[item.status].status === 'final' && can(uiPermissions.REPORTS_INGREDIENTS_WEEKLY_STATUS_APPROVE)"
                    type="button"
                    class="dropdown-item"
                    @click="submit(item, 'approved')">
                    <i class="dropdown-icon fe fe-thumbs-up"></i> Approve
                  </button>
                  <button
                    v-if="StockStatus[item.status].status === 'pending' && canSubmitForApproval"
                    type="button"
                    class="dropdown-item"
                    @click="submit(item, 'final')">
                    <i class="dropdown-icon fe fe-send"></i> Submit for approval
                  </button>
                </div>
              </div>
            </td>
            <td>
              <a
                v-if="can(uiPermissions.REPORTS_INGREDIENTS_VIEW)"
                href="#"
                @click.prevent="$emit('filterDate', item.date)">
                {{`${moment(item.date).format('ddd, D MMM YY')}`}}
              </a>
              <template v-else>
                {{`${moment(item.date).format('ddd, D MMM YY')}`}}
              </template>
            </td>
            <td>
              AED {{numeral(item.stock_value).format('0,0.00')}}
            </td>
            <td>
              <i
                class="fe fe-circle rounded-circle mr-1"
                :class="`bg-${StockStatus[item.status].color} text-${StockStatus[item.status].color}`"></i>
              {{item.status}}
            </td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="3">There are no results matching your criteria.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination
      :currentPage="page"
      :totalPages="meta.totalPages"
      @goToPage="e => customPage(e)"></pagination>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {stocks} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';
import StockStatus from '@/assets/enums/StockStatus';
import moment from 'moment-timezone';
import Permissions from '@hellochef/shared-js/enums/Permissions';

export default {
  components: {
    Pagination,
  },
  mixins: [
    list,
    submitting,
  ],
  props: {
    routeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      StockStatus,
      items: [],
    };
  },
  computed: {
    canSubmitForApproval() {
      return (this.routeName === 'articles' && this.can(Permissions.REPORTS_INGREDIENTS_WEEKLY_STATUS_SUBMIT_ARTICLE_APPROVAL)) ||
      (this.routeName === 'others' && this.can(Permissions.REPORTS_INGREDIENTS_WEEKLY_STATUS_SUBMIT_PACKAGING_APPROVAL));
    },
  },
  watch: {
    routeName() {
      this.refresh();
    },
  },
  methods: {
    customPage(e) {
      this.page = e.page;

      this.refresh();
    },
    fetchData(page) {
      const params = {
        page,
        allowed_in_recipes: this.routeName === 'articles' ? 1 : 0,
      };

      return stocks.getWeeklyStatus({params});
    },
    async submit(item, status) {
      this.submitting = true;
      try {
        await stocks.updateWeeklyStatus({status}, {

          params: {

            week: moment(item.date).format('YYYY-MM-DD'),
            allowed_in_recipes: this.routeName === 'articles' ? 1 : 0,
          },
        });

        this.submitting = false;
        this.$emit('success');
      }
      catch (ex) {
        this.$emit('error');
        console.error(ex);
      }
      finally {
        this.refresh();
      }
    },
  },
};
</script>
