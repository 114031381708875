<template>
  <div class="card">
    <div class="card-header">
      <div class="card-title">
        Top stock articles on {{`${moment(startDate).format('dd, D MMM YY')}`}}
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-outline table-vcenter card-table">
        <thead>
          <tr>
            <th>Article</th>
            <th class="text-center">Stock</th>
            <th class="text-center">Value</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.article_id">
            <td style="width: 35%;">
              <router-link :to="`/articles/${item.article_id}`">{{(item.article || {}).name}}</router-link>
            </td>
            <td class="text-center">
              {{numeral(item.qty).format('0,0.00')}} {{(item.ordering_uom || {}).name}}
            </td>
            <td class="text-center">
              AED {{numeral(item.value).format('0,0.00')}}
            </td>
          </tr>
          <tr v-if="items.length === 0">
            <td colspan="3">There are no results matching your criteria.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagination
      :currentPage="page"
      :totalPages="meta.totalPages"
      @goToPage="(e) => customPage(e)"></pagination>
  </div>

</template>

<script>

import Pagination from '@/components/Pagination.vue';
import list from '@/mixins/list';
import {stocks} from '@/services';
import submitting from '@hellochef/shared-js/mixins/submitting';
import moment from 'moment-timezone';

export default {

  components: {

    Pagination,
  },
  mixins: [

    list,
    submitting,
  ],

  props: {

    startDate: Object,
    routeName: {
      type: String,
      required: true,
    },
  },
  watch: {

    startDate(newValue) {
      if (newValue) { this.refresh(); }
    },
  },
  methods: {

    customPage(e) {
      this.page = e.page;

      this.refresh();
    },
    fetchData(page) {
      return stocks.getByParameters({

        column: 'value',
        end: moment(this.startDate).format('YYYY-MM-DD'),
        limit: 5,
        page,
        start: moment(this.startDate).format('YYYY-MM-DD'),
        with: 'orderingUom,article',
        allowed_in_recipes: this.routeName === 'articles' ? 1 : 0,
      });
    },
    transformData(result) {
      result.items = result.stock;

      return result;
    },
  },
};
</script>
