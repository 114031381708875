<template>
  <ul
    class="nav nav-tabs w-100 mx-0"
    style="margin-top: -1rem;"
    role="tablist">
    <li class="nav-item">
      <router-link
        tabindex="0"
        to="/inventory/reports/stocks/articles"
        class="nav-link"
        :class="{'active': tab === 'articles'}"
        role="tab">
        Articles
      </router-link>
    </li>
    <li v-if="can(uiPermissions.REPORTS_INGREDIENTS_VIEW)" class="nav-item">
      <router-link
        tabindex="0"
        to="/inventory/reports/stocks/others"
        class="nav-link"
        :class="{'active': tab === 'others'}"
        role="tab">
        Packaging & Other Items
      </router-link>
    </li>
  </ul>
</template>

<script>

export default {

  props: [

    'tab',
  ],
};

</script>
